import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { HomePageData } from '@jsCore/src/types/providers';

export const getHomePageDataUseCase = (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository
): Promise<HomePageData> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchHomePageData(language);
};
