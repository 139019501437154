import { PrivacyNotice } from '@jsCore/src/types/providers';
import {
  ConfigRepository,
  TermsRepository
} from '@jsCore/src/types/repositories';

export const getPrivacyNoticeUseCase = async (
  configRepository: ConfigRepository,
  termsRepository: TermsRepository
): Promise<PrivacyNotice> => {
  const language = configRepository.getLanguage();
  return termsRepository.fetchPrivacyNotice(language);
};
