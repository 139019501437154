import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { CampaignDetail } from '@jsCore/src/types/providers';

export const getCampaignDetailUseCase = (
  configRepository: ConfigRepository,
  slug: string,
  contentRepository: ContentRepository
): Promise<CampaignDetail> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchCampaignDetail(language, slug);
};
