import { PrivacyPolicy } from '@jsCore/src/types/providers';
import {
  ConfigRepository,
  TermsRepository
} from '@jsCore/src/types/repositories';

export const getTermsAndConditionsUseCase = async (
  configRepository: ConfigRepository,
  termsRepository: TermsRepository
): Promise<PrivacyPolicy> => {
  const language = configRepository.getLanguage();
  return termsRepository.fetchTermsAndConditions(language);
};
