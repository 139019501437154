import { LanguageTypeISO2 } from '@jsCore/src/config';
import {
  NjoiApiProvider,
  PrivacyNotice,
  PrivacyPolicy,
  TermsAndConditions,
  TermsOfUse
} from '@jsCore/src/types/providers';
import { TermsRepository } from '@jsCore/src/types/repositories';

class DefaultTermsRepository implements TermsRepository {
  private njoiApiProvider: NjoiApiProvider;

  constructor(njoiApiProvider: NjoiApiProvider) {
    this.njoiApiProvider = njoiApiProvider;
  }

  async fetchPrivacyNotice(language: LanguageTypeISO2): Promise<PrivacyNotice> {
    const response = await this.njoiApiProvider.getPrivacyNotice(language);
    return response.data.response;
  }

  async fetchPrivacyPolicy(language: LanguageTypeISO2): Promise<PrivacyPolicy> {
    const response = await this.njoiApiProvider.getPrivacyPolicy(language);
    return response.data.response;
  }

  async fetchTermsAndConditions(
    language: LanguageTypeISO2
  ): Promise<TermsAndConditions> {
    const response = await this.njoiApiProvider.getTermsAndConditions(language);
    return response.data.response;
  }

  async fetchTermsOfUse(language: LanguageTypeISO2): Promise<TermsOfUse> {
    const response = await this.njoiApiProvider.getTermsOfUse(language);
    return response.data.response;
  }
}

export default DefaultTermsRepository;
